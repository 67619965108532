import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

export default function Publications() {
  const data = useStaticQuery(
    graphql`
      query {
        image: file(
          relativePath: {
            eq: "alfons-morales-YLSwjSy7stw-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        publications: allMarkdownRemark(
          filter: {
            fields: { collection: { eq: "publications" } }
          }
        ) {
          edges {
            node {
              frontmatter {
                title
              }
              html
            }
          }
        }
      }
    `,
  );

  return (
    <React.Fragment>
      <style>
        {`
          .markdown {
            // padding-left: 3.5em;
            // text-indent: -3.5em;    
          }
          .markdown p {
            padding-bottom: 1rem;
          }
        `}
      </style>
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <Img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              fluid={data.image.childImageSharp.fluid}
              alt="Books with a view"
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h2 className="leading-6 text-green-600 font-semibold tracking-wide uppercase"></h2>
              <h1 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10 pb-4">
                Publications
              </h1>
              {data.publications.edges.map((edge) => (
                <div key={edge.node.frontmatter.title}>
                  <h2 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                    {edge.node.frontmatter.title}
                  </h2>
                  <p
                    className="mt-3 text-base leading-6 text-gray-500 markdown"
                    dangerouslySetInnerHTML={{
                      __html: edge.node.html,
                    }}
                  ></p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
